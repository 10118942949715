export const overviewItems = [
  'MRR-active-last-month',
  'MRR-active-current-month',
  'MRR-pending',
  'connections',
  'buildings',
  'premises',
  'ARPU',
  'Uptake',
  'Churn',
  'churn-last-month',
  'churn-current-month'
]
