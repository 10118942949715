export const overviewTitles = [
  'MRR last month',
  'MRR current month',
  'Pending MRR current month',
  'Total connections',
  'Total on-net buildings',
  'Total premises passed',
  'ARPU',
  'Uptake',
  'Churn',
  'Churn current month',
  'Churn last month'
]
